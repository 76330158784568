(function($){
    /**
     * rozwijanie opisu w pinach miasta
     */
    $(document).on('mouseenter', '.js-city-pin', function() {
        $('.map__pin-text', this).css({
            display: 'flex'
        }).animate({
            opacity: 1,
            left: 0
        }, 200);
    })

    $(document).on('mouseleave', '.js-city-pin', function() {
        var text = $('.map__pin-text', this);
        text.animate({
            opacity: 0,
            left: -60
        }, 200, function() {
            text.hide();
        });
    })
})(jQuery);