/**
 * lista punktów na mapie
 */
var points = [
   {
      active: false,
      name: 'Osiedle',
      icon: 'osiedle.svg'
   },
   {
      active: false,
      name: 'Obiekt przemysłowy',
      icon: 'magazine.svg'
   },
   {
      active: false,
      name: 'Szpital',
      icon: 'hospital.svg'
   },
   {
      active: false,
      name: 'Dworzec kolejowy',
      icon: 'train.svg'
   },
   {
      active: true,
      onMap: true,
      name: 'Biurowiec',
      subname: '15 interakcji',
      id: '1_office',
      posX: 4547,
      posY: 2667,
      icon: 'office.svg'
   },
   {
      active: false,
      name: 'Parking',
      icon: 'parking.svg'
   },
   {
      active: false,
      name: 'Port lotniczy',
      icon: 'airport.svg'
   },
   {
      active: false,
      name: 'Uczelnia wyższa',
      icon: 'collage.svg'
   },
   {
      active: false,
      name: 'Pętla autobusowa',
      icon: 'bus.svg'
   },
   {
      active: false,
      name: 'Przejazd kolejowy',
      icon: 'przejazdkolejowy.svg'
   },
   {
      active: false,
      name: 'Centrum handlowe',
      icon: 'mall.svg'
   },
   {
      active: false,
      name: 'Jednostka wojskowa',
      icon: 'army.svg'
   },
   {
      active: false,
      name: 'Więzienie',
      icon: 'prison.svg'
   },
   {
      active: false,
      name: 'Przystań',
      icon: 'port.svg'
   },
   {
      active: true,
      onMap: true,
      name: 'Miasto',
      posX: 3047,
      posY: 1867,
      icon: 'city.svg',
      subname: '14 interakcji',
      id: '2_city'
   },
]

city_points = [
   {
      active: true,
      type: 'intercom',
      name: 'Intercom',
      icon: 'intercom.svg',
      subname: '12 interakcji',
      id: '1_office',
      posX: 3547, 
      posY: 1667
   },
   {
      active: true,
      type: 'camera',
      name: 'Kamera',
      icon: 'camera.svg',
      subname: '12 interakcji',
      id: '1_office',
      posX: 2547, 
      posY: 2167
   }

];

var icon_path = 'dist/img/map/'
var map_width = 4000
var map_height = 3000
var stage

/**
 * ładowanie mapy
 * @param {int} containerWidth 
 * @param {int} containerHeight 
 */
function loadCanvas(containerWidth, containerHeight) {

   /**
    * inicjowanie sceny
    */
   dragX = (map_width / 2) - (containerWidth / 2);
   dragY = (map_height / 2) - (containerHeight / 2);

   stage = new Konva.Stage({
      container: "stage",
      width: containerWidth,
      height: containerHeight,
      draggable: true,
      dragBoundFunc: function (pos) {
         var newX = pos.x > dragX ? dragX : (pos.x < -dragX ? -dragX : pos.x),
            newY = pos.y > dragY ? dragY : (pos.y < -dragY ? -dragY : pos.y)

         return {
            x: newX,
            y: newY
         }
      }
   })

   /**
    * podkład
    */
   var map_background = new Konva.Layer()
   var imageObj = new Image()
   imageObj.onload = function () {
      var map = new Konva.Image({
         x: -dragX,
         y: -dragY,
         // x: 0,
         // y: 0,
         image: imageObj,
         width: map_width,
         height: map_height
      })

      map_background.add(map)
      map_background.batchDraw()
   }
   imageObj.src = 'dist/img/mapa.png';
   stage.add(map_background)

   /**
    * znaczniki
    */
   var markers = new Konva.Layer()

   points.forEach(function(el, index) {
      if (el.active) {
         //pozycja na mapie
         var posX = el.posX - dragX - 36;
         var posY = el.posY - dragY - 36;

         var group = new Konva.Group({
            x: posX,
            y: posY,
            id: el.id
         })

         //ikona
         var iconObj = new Image();
         iconObj.onload = function () {
            var icon = new Konva.Image({
               image: imageObj
            })
         }
         iconObj.src = icon_path + el.icon
         
         var rect_front = new Konva.Rect({
            x: 5,
            y: 5,
            width: 62,
            height: 62,
            fill: '#FFFFFF',
            stroke: '#2d74ff',
            cornerRadius: 31,
            strokeWidth: 1,
            fillPatternRepeat: 'no-repeat',
            fillPatternImage: iconObj,
            fillPriority: 'pattern',
         })

         //elemnty grupy
         var rect_back = new Konva.Rect({
            width: 72,
            height: 72,
            cornerRadius: 36,
            fillLinearGradientStartPoint: {
               x: 0,
               y: 0
            },
            fillLinearGradientEndPoint: {
               x: 100,
               y: 180
            },
            fillLinearGradientColorStops: [0, '#081670', 1, '#233398'],
            opacity: 0
         })

         var text_headline = new Konva.Text({
            x: 52,
            y: 16,
            text: el.name,
            fontSize: 20,
            fontFamily: 'Muli',
            fill: '#FFFFFF',
            fontStyle: '800',
            opacity: 0,
         })

         var text_second = new Konva.Text({
            x: 52,
            y: 42,
            text: el.subname,
            fontSize: 13,
            fontFamily: 'Muli',
            fill: '#858ec6',
            opacity: 0,
         })

         group.add(rect_back, rect_front, text_headline, text_second)
         markers.add(group)
         
         setTimeout(function() {
            stage.draw()
         }, 250)

         //animacje
         var background = group.children[0]
         var text_name = group.children[2]
         var text_subname = group.children[3]
         var group_width = (text_name.width() < 100) ? 220 : text_name.width() + 122

         var back_tween = new Konva.Tween({
            node: background,
            width: group_width,
            opacity: 1,
            duration: 0.15
         })

         var text1_tween = new Konva.Tween({
            node: text_name,
            opacity: 1,
            x: 82,
            duration: 0.15
         })

         var text2_tween = new Konva.Tween({
            node: text_subname,
            opacity: 1,
            x: 82,
            duration: 0.15
         })

         //animacje na hover
         group.on('mouseover', function () {
            document.body.style.cursor = 'pointer'
            back_tween.play()
            text1_tween.play()
            text2_tween.play()
         })

         group.on('mouseout', function () {
            document.body.style.cursor = 'default';
            back_tween.reverse()
            text1_tween.reverse()
            text2_tween.reverse()
         });

         //akcje na click
         group.on('click', function () {
            //przesunięcie mapy
            moveMapToPoint(el.id)

            //przekazanie wybranej wartosci
            var event = new Event('showBuilding')
            event.id = this.getAttr('id')
            window.dispatchEvent(event)

         }); 
      }
   })
   stage.add(markers)
   stage.draw()

   /**
    * nawigacja z klawiatury
    */
   var container = stage.container();
   container.tabIndex = 1;
   container.focus()
   var DELTA = 20;

   container.addEventListener('keydown', function (e) {
      if (e.keyCode === 37) {
         if (stage.getAttr('x') < dragX) {
            e.preventDefault()
            stage.x(stage.x() + DELTA)
            stage.draw()
         } 
      }
      
      if (e.keyCode === 39) {
         if (stage.getAttr('x') > -dragX) {
            e.preventDefault()
            stage.x(stage.x() - DELTA);
            stage.draw()
         } 
      }

      if (e.keyCode === 38) {
         if (stage.getAttr('y') < dragY - 20) {
            e.preventDefault()
            stage.y(stage.y() + DELTA);
            stage.draw()
         } 
      }

      if (e.keyCode === 40) {
         if (stage.getAttr('y') > -dragY + 20) {
            e.preventDefault()
            stage.y(stage.y() - DELTA);
            stage.draw()
         } 
      }
   });
}

/**
 * przesuwanie mapy do punktu
 * @param {string} id 
 */
function moveMapToPoint(id) {
   if (stage.find('#' + id).length > 0) {
      var current = points.find(function(el) {
         if (el.id == id) {
            return el
         }
      })

      var stage_width = stage.width()
      var stage_height = stage.height()

      //przesuniecie stage'a
      var dragToX = -(current.posX - dragX) + (stage_width / 2)
      var dragToY = -(current.posY - dragY) + (stage_height / 2) - 36

      var checkX = map_width - current.posX + (stage_width / 2)
      var checkY = map_height - current.posY + (stage_height / 2)

      var stage_tween = new Konva.Tween({
         node: stage,
         x: checkX < dragX ? -dragX : (checkX > map_width) ? dragX : dragToX,
         y: checkY < dragY ? -dragY : (checkY > map_height) ? dragY : dragToY,
         duration: 0.15
      });
      stage_tween.play()
   }
}

function setFocusOnMap() {
   var container = stage.container();
   container.tabIndex = 1;
   container.focus()
}

/**
 * event polyfill
 */
(function () {
   if ( typeof window.CustomEvent === "function" ) return false;
 
   function CustomEvent ( event, params ) {
      params = params || { bubbles: false, cancelable: false, detail: null };
      var evt = document.createEvent( 'CustomEvent' );
      evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
      return evt;
   }
   window.CustomEvent = CustomEvent;
})();

/**
 * array find polyfill
 */
// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
   Object.defineProperty(Array.prototype, 'find', {
      value: function (predicate) {
         // 1. Let O be ? ToObject(this value).
         if (this == null) {
            throw TypeError('"this" is null or not defined');
         }

         var o = Object(this);

         // 2. Let len be ? ToLength(? Get(O, "length")).
         var len = o.length >>> 0;

         // 3. If IsCallable(predicate) is false, throw a TypeError exception.
         if (typeof predicate !== 'function') {
            throw TypeError('predicate must be a function');
         }

         // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
         var thisArg = arguments[1];

         // 5. Let k be 0.
         var k = 0;

         // 6. Repeat, while k < len
         while (k < len) {
            // a. Let Pk be ! ToString(k).
            // b. Let kValue be ? Get(O, Pk).
            // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
            // d. If testResult is true, return kValue.
            var kValue = o[k];
            if (predicate.call(thisArg, kValue, k, o)) {
               return kValue;
            }
            // e. Increase k by 1.
            k++;
         }

         // 7. Return undefined.
         return undefined;
      },
      configurable: true,
      writable: true
   });
}