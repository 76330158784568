//angular app
var angularApp = angular.module('map', []);

angularApp.controller('mapController', ['$http', '$scope', '$rootScope', '$timeout', '$window', '$document', '$compile', 'MoviesServices', function ($http, $scope, $rootScope, $timeout, $window, $document, $compile, MoviesServices) {
    var c = $scope;
      
    //init data
    c.selected_id = null
    c.points = points
    c.init = false
    //widok listy filmów - init
    c.show_movies = false
    c.objectData = false
    //widok filmu - init
    c.load_single_movie = true
    c.show_single_movie = false
    c.videoData = false
    //status filmu
    c.video_state = 'pause'
    //status modala
    c.modal_shown = false
    c.modal_loader = false
    //ekran startowy
    c.init_screen = true
    //nawigacja
    c.nav_screen = false
    c.city_screen = false
    c.city_points = city_points;
    c.filters = {
        'intercom': true,
        'camera': true
    };
    
    c.mapAnim = false;
    c.cityAnim = false;
    c.currentAnim = '';

    /**
     * nasłuchiwanie klików z mapy
     */
    $window.addEventListener('showBuilding', function(e) {
        c.selected_id = e.id
        c.$apply()
    })

    /**
     * obsługa z klawiatury     
     */
    $window.addEventListener('keydown', function (e) {
        //esc
        if (e.keyCode === 27) {
            if (c.show_movies == true && c.show_single_movie == false) {
                c.hidePopup()
                c.$apply()
            }
            else if (c.show_movies == true && c.show_single_movie == true && c.modal_shown == false) {
                c.resetVideo()
                c.$apply()
            }
            else if (c.show_movies == true && c.show_single_movie == true && c.modal_shown == true) {
                c.modal_shown = false;
                c.$apply()
            }
        }
        //spacja
        else if (e.keyCode === 32) {
            if (c.show_movies == true && c.show_single_movie == true) {
                c.togglePlay()
                c.$apply()
            }
        }
    })

    /**
     * przesyłanie klików do mapy
     */
    c.moveMap = function(id) {
        if( id == '2_city' ) {
            c.showCity();
            return;
        }

        // moveMapToPoint(id)
        c.selected_id = id
        c.nav_screen = false
        c.init_screen = false
        c.city_screen = false
        c.show_movies = true;
    }

    /**
     * przelaczenie na widok miasta
     */
    c.showCity = function() {
        // console.log('city');
        // moveMapToPoint(id)
        // c.selected_id = id
        c.nav_screen = false
        c.init_screen = false
//        c.show_movies = true;
        c.city_screen = true;
        c.objectData = views['2_city'];

        c.currentAnim = 'city';
        
        if( c.cityAnim )
            return;
            
        // console.log(c.anim);

        var params = {
            container: document.getElementById('city'),
            renderer: 'html',
            loop: true,
            autoplay: true,
            cityAnimationData: cityAnimationData
        };
    
        c.cityAnim = city.loadAnimation(params);
        
        for(let cat in c.objectData.categories) {
            // console.log(cat);
            for(let el in c.objectData.categories[cat].points) {
                $("#city > div").append($compile(`<div data-ng-show="filters['${c.objectData.categories[cat].points[el].type}']" class="map__pin map__pin--play js-map-pin" style="top: ${c.objectData.categories[cat].points[el].posY}px; left: ${c.objectData.categories[cat].points[el].posX}px;">
                    <div class="map__pin-bg map__pin-bg--play"></div> 
                    <div class="map__pin-play"> 
                        <img src="dist/img/play.svg" />
                    </div>
                    <div class="map__pin-animations-list-container js-animation-list-container">
                        <ul class="map__pin-animations-list js-animation-list">
                            <li class="map__pin-animation-trigger">
                                <span data-ng-click="showCityVideo(${cat},${el},1, $event)">${c.objectData.categories[cat].points[el].name}</span>                                
                            </li>
                        </ul>
                    </div>
                </div>`)($scope));
            }
        };

        initializeMap("city");
        c.scaleMap("city");
    }

    c.setFocusOnMap = function() {
        setFocusOnMap()
    }

    c.scaleMap = function(id) {
        scaleMap(id)
    }

    /**
     * ukrywanie listy filmów
     */
    c.hidePopup = function(source) {
        c.resetVideo()
        c.show_movies = false
        c.city_screen = false
        c.selected_id = null

        if( source == "city" ) {
            // if( c.anim )
            //     c.anim.destroy();

            // initializeMap("#lottie-wraper");
            // c.scaleMap("lottie");
            c.goToMap();
        } 

        // setFocusOnMap()
    }

    /**
     * śledzenie wybranego obiektu i ładowanie widoku
     */
    c.$watch('selected_id', function (newValue, oldValue, scope) {
        if (newValue != null) {
            // console.log(c.selected_id);
            c.objectData = views[c.selected_id]
            c.show_movies = true
            // $http({
            //     method: 'GET',
            //     url: 'views/' + c.selected_id + '.json'
            // })
            // .then(function successCallback(response) {
            //     c.objectData = response.data
            //     c.show_movies = true
            // }, 
            // function errorCallback(response) {
            //     console.log(response)
            // }); 
        }
        else {
            c.show_movies = false
        }
    });

    /**
     * wyświetlanie video
     * 
     * @param {string} - id punktu
     * @param {string} - id filmu
     * @param {string} - event
     */
    c.showVideo = function(point_id, video_id, e) {
        e.preventDefault();
        c.videoData = c.objectData['points'][point_id]['movies'][video_id];
        c.show_single_movie = true

        $timeout(function() {
            c.togglePlay()
        })
    }

    /**
     * wyświetlanie video z miasta
     * 
     * @param {string} - id punktu
     * @param {string} - id filmu
     * @param {string} - event
     */
    c.showCityVideo = function(category_id, point_id, video_id, e) {
        e.preventDefault();
        c.videoData = c.objectData['categories'][category_id]['points'][point_id]['movies'][video_id];
        c.show_single_movie = true

        $timeout(function() {
            c.togglePlay()
        })
    }

    /**
     * ukrywanie i reset video
     */
    c.resetVideo = function() {
        c.videoData = false;
        c.load_single_movie = true
        c.show_single_movie = false
        c.video_state = 'pause'
    }

    /**
     * nawigacja video
     */
    c.togglePlay = function() {
        var video = angular.element(document.querySelector('.jsVideoPlayer.active'))

        if (MoviesServices.checkMovieStart(video[0])) {
            // console.log(video[0]);
            c.load_single_movie = true

            var b = setInterval(function() {
                c.video_ready = MoviesServices.checkForVideo(video[0])
                if (c.video_ready) {
                    clearInterval(b)
                    $timeout(function() {
                        c.video_state = MoviesServices.togglePlay(video[0])
                        c.load_single_movie = false
                        c.$apply()
                    }, 100)
                }
            }, 50)
        }
        else {
            c.video_state = MoviesServices.togglePlay(video[0])
        }
    }

    /**
     * nasłuchiwanie zakończenia video
     */
    $window.addEventListener('videoEnded', function(e) {
        c.video_state = 'pause'
        if (c.videoData != false && c.videoData.show_gallery) {
            c.toggleModal()
        }
        c.$apply()
    })

    /**
     * modal
     */
    c.toggleModal = function() {
        c.modal_shown = !c.modal_shown;
        var video = angular.element(document.querySelector('.jsVideoPlayer.active'))
        c.video_state = 'pause'
        video[0].pause()
    };

    /**
     * inicjowanie mapy
     */
    c.init = function () {
        // var windowWidth = $window.innerWidth
        // var windowHeight = $window.innerHeight
        // loadCanvas(windowWidth - 400, windowHeight);
        $timeout(function() {
            c.init = true
        }, 500)
    };

    c.goToMap = function() {
        c.init_screen = false;
        c.currentAnim = 'map';
        
        if( c.mapAnim )
            return;

        var params = {
            container: document.getElementById('lottie'),
            renderer: 'html',
            loop: true,
            autoplay: true,
            lottieAnimationData: lottieAnimationData
        };
        
        c.mapAnim = lottie.loadAnimation(params)

        // console.log(c.mapAnim);
        
        points.forEach(function(el, index) {
            if (el.onMap) {
                $("#lottie > div").append($compile(`<a data-ng-click="moveMap('${el.id}')" class="map__pin js-city-pin" style="top: ${el.posY}px; left: ${el.posX}px;">
                    <div class="map__pin-bg"></div>
                    <div class="map__pin-icon map__pin-icon--office">
                        <img src="dist/img/icons/${el.icon}" />
                    </div>
                    <div class="map__pin-text">
                        <h3>${el.name}</h3>
                        <p>${el.subname}</p> 
                    </div>
                </a>`)($scope));
            }
        });

        c.scaleMap("lottie");
    }

    c.toggleFilter = function(type) {
        c.filters[type] = !c.filters[type];
    }
}])

/**
 * towrzenie slidera
 */
angularApp.directive('ngSlick', ['$timeout', function($timeout) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            $timeout(function () {
                scope.$watch('modal_shown', function (newValue, oldValue, scope) {
                    if (newValue == true) {
                        if (scope.videoData != false) {
                            scope.modal_loader = true
                            //inicjalizacja slick
                            element.not('.slick-initialized').slick({
                                infinite: false,
                                prevArrow: '<span class="slider-nav slider-nav--prev"><span class="icon-arrow-prev"></span></span>',
                                nextArrow: '<span class="slider-nav slider-nav--next"><span class="icon-arrow-next"></span></span>',
                                arrows: true,
                                dots: false,
                                focusOnSelect: true,
                                focusOnChange: true,
                                fade: true
                            })
                            //tworzenie slajdów
                            for (var key in scope.videoData.gallery) {
                                var item = scope.videoData.gallery[key]
                                if (item['type'] == 'video') {
                                    var template = '<div class="modal-gallery__content"><video class="modal-gallery__video" controls muted><source src="' + item['url'] + '" type="video/mp4"></video></div>'
                                }
                                else {
                                    var template = '<div class="modal-gallery__content"><image class="modal-gallery__image" src="' + item['url'] + '" /></div>'
                                }
                                element.slick('slickAdd', '<div class="modal-gallery" data-type="' + item['type'] + '">' + template + '</div>')
                            }
                            //focus
                            element.slick('slickGoTo', 0)
                            $timeout(function() {
                                scope.modal_loader = false
                            }, 300)

                            //odpalenie filmu po zmianie
                            element.on('afterChange', function(event, slick, currentSlide){
                                var slide = element.find('.slick-slide')[currentSlide]
                                var type = slide.getAttribute('data-type')

                                if (type == 'video') {
                                    slide.getElementsByTagName('video')[0].play()
                                }
                            })

                            //pauza filmu przed zmianie
                            element.on('beforeChange', function(event, slick, currentSlide, nextSlide){
                                var slide = element.find('.slick-slide')[currentSlide]
                                var type = slide.getAttribute('data-type')

                                if (type == 'video') {
                                    slide.getElementsByTagName('video')[0].pause()
                                }
                            })
                        }
                    }
                    else {
                        //usuwanie slicka
                        if (element.hasClass('slick-initialized')) {
                            element.slick('unslick')
                            element.html('')
                        }
                    }
                })
            });
        }
    }
}])

/**
 * modal
 */
angularApp.directive('modalWindow', function() {
    return {
        restrict: 'E',
        scope: {
            show: '='
        },
        replace: true,
        transclude: true,
        link: function(scope, element, attrs) {
            scope.hideModal = function() {
                scope.show = false;
            };
        },
        template: '<div ng-show="show"><div class="modal-overlay" ng-click="hideModal()"></div><div class="modal-background"><div class="modal-close" ng-click="hideModal()"><span class="icon-close"></span></div><div class="modal-content" ng-transclude></div></div></div>'
    };
});

/**
 * renderowanie html
 */
angularApp.filter('renderHTMLCorrectly', function($sce) {
	return function(stringToParse) {
		return $sce.trustAsHtml(stringToParse);
	}
});

/**
 * obsługa video
 */
angularApp.factory('MoviesServices', ['$http', '$window', function($http, $window) {
    return {
        /**
         * pause/play
         */
        togglePlay: function(video) {
            // aktualizowanie progress bar
            video.addEventListener('timeupdate', updateProgress, false);
            video.addEventListener('ended', endVideo, false);

            // toggle play/pause
            if (video.paused) {
                video.play()
                return 'play'
            } 
            else {
                video.pause()
                return 'pause'
            }

            //progress bar
            function updateProgress() {
                var progress_bar = angular.element(document.querySelector('.jsVideoProgressBar.active'))
                var value = 0;
                if (video.currentTime > 0) {
                    value = Math.floor((100 / video.duration) * video.currentTime);
                }
                progress_bar.css({width: value + "%"});
            }

            function endVideo() {
                var event = new Event('videoEnded')
                event.ended = 'true'
                $window.dispatchEvent(event)
            }
        },

        checkForVideo: function(video) {
            if (video.readyState >= 3){
                return true
            }               
            else {
                return false
            }    
        },

        checkMovieStart: function(video) {
            if (video.currentTime > 0) {
                return false
            }
            else {
                return true
            } 
        }
    }
}]);

var zoom = 0.25999999999999934;
var scale = 1;

//consts
var MAP_VIEWPORT_WIDTH = $(window).width() - 400;
var MAP_VIEWPORT_HEIGHT = $(window).height();
//console.log(MAP_VIEWPORT_HEIGHT + " " + MAP_VIEWPORT_WIDTH);
var MAP_WIDTH = 6000;
var MAP_HEIGHT = 4500;
var DELTA = 20;
var wheelListener = false;

function scaleMap(id) {
    // console.log('scaleMap ' + id);
    var box = document.getElementById(id);

    transformValue = 'scale('+zoom+')';
    box.style.WebkitTransform = transformValue;
    box.style.MozTransform = transformValue;
    box.style.OTransform = transformValue;
    box.style.transform = transformValue;
    // box.style.transformOrigin = e.layerX + "px " + e.layerY + "px";

    scale = 10 / (zoom * 10);
    $(".map__pin", "#" + id).css({
        transform: 'scale('+ scale +')'
    });
}

function initializeMap(source) {
    /**
     * zoom / drag
     */ 
    
    var id = "#" + source + "-wraper";

    $(id).css({
        left: -2226,
        top: -1725
    });

    $(window).resize(function() {
        MAP_VIEWPORT_WIDTH = $(window).width() - 400;
        MAP_VIEWPORT_HEIGHT = $(window).height();
        //console.log(MAP_VIEWPORT_HEIGHT + " " + MAP_VIEWPORT_WIDTH);
    }); 

    var el = document.getElementById(source + "-wraper"); 
    wheelListener = el.addEventListener("wheel", function (e) {
        if( wheelListener )
            return false;

        if( $(".movie-popup").hasClass('visible') && !$(".movie-popup").hasClass('city-screen') )
            return false;

        // console.log(zoom + " " + scale);

        if (e.deltaY < 0)
        {
            //console.log('scrolling up');

            if( zoom < 1 ) {
                zoom += 0.02;  
                scale -= 0.02;
            }
        }
        else if (e.deltaY > 0)
        {
            //console.log('scrolling down');  
           
            if( canIZoom() ) {
                if( zoom > 0.25 ) {
                    zoom -= 0.02;
                    scale += 0.02;
                } 
            }
        }

        scaleMap(source);
    });

    function canIZoom() {
        var offsetLeft = $(id).offset().left;
        var dragWidth = $(id).width() * (zoom - 0.02);
        var dragViewportWidthDiff = dragWidth - MAP_VIEWPORT_WIDTH;
        var leftBoundry = (dragWidth - MAP_WIDTH) / 2;

        // console.log(id);
        
        //jesli wychodzi biale tlo z lewej
        if( offsetLeft > leftBoundry )
            return false;                

        //jesli wychodzi biale tlo z prawej
        if(offsetLeft < -dragViewportWidthDiff + leftBoundry) 
            return false; 
        
        var offsetTop = $(id).offset().top;
        var dragHeight = $(id).height() * (zoom - 0.02);
        var dragViewportHeightDiff = dragHeight - MAP_VIEWPORT_HEIGHT;
        var topBoundry = (dragHeight - MAP_HEIGHT) / 2;
        
        //jesli wychodzi biale tlo z gory
        if( offsetTop > topBoundry )
            return false;                

        //jesli wychodzi biale tlo z dolu
        if(offsetTop < -dragViewportHeightDiff + topBoundry) 
            return false;

        return true;
    }

    $(".map__controls-zoom--out").click(function() {
        if( canIZoom() ) {
            if( zoom > 0.25 ) {
                zoom -= 0.02;
                scale += 0.02;
                scaleMap(source);
            } 
        }
    });

    $(".map__controls-zoom--in").click(function() {
        if( zoom < 1 ) {
            zoom += 0.02;  
            scale -= 0.02;
            scaleMap(source);
        }
    });

    $( id ).draggable({
        drag : function(event,ui){
          var parent = ui.helper[0].parentNode;
    
          var dragHeight = ui.helper[0].clientHeight;
          var dragViewportHeightDiff = zoom * dragHeight - MAP_VIEWPORT_HEIGHT;
          var topBoundry = (zoom * dragHeight - MAP_HEIGHT) / 2;

          var dragWidth = ui.helper[0].clientWidth;
          var dragViewportWidthDiff = zoom * dragWidth - MAP_VIEWPORT_WIDTH;
          var leftBoundry = (zoom * dragWidth - MAP_WIDTH) / 2;
          
          if(ui.position.left > leftBoundry) ui.position.left = leftBoundry;
          else if(ui.position.left < -dragViewportWidthDiff + leftBoundry) ui.position.left =  -dragViewportWidthDiff + leftBoundry;
          
          if(ui.position.top > topBoundry) ui.position.top = topBoundry;
          else if(ui.position.top < -dragViewportHeightDiff + topBoundry) ui.position.top = -dragViewportHeightDiff + topBoundry;

        //   console.log(zoom);
        //   console.log(ui.position); 
          
          }
    });

    document.addEventListener('keydown', function (e) {
        var offsetLeft = $(id).offset().left;
        var dragWidth = $( id).width() * (zoom - 0.02);
        var dragViewportWidthDiff = dragWidth - MAP_VIEWPORT_WIDTH;
        var leftBoundry = (dragWidth - MAP_WIDTH) / 2;

        var offsetTop = $(id).offset().top;
        var dragHeight = $( id).height() * (zoom - 0.02);
        var dragViewportHeightDiff = dragHeight - MAP_VIEWPORT_HEIGHT;
        var topBoundry = (dragHeight - MAP_HEIGHT) / 2;

        if (e.keyCode === 37) {
            if( offsetLeft + DELTA < leftBoundry ) {
                $(id).css({
                    left: offsetLeft + DELTA
                });
            }
        }
        
        if (e.keyCode === 39) {
            if( offsetLeft + DELTA > -dragViewportWidthDiff + leftBoundry ) {
                $(id).css({
                    left: offsetLeft - DELTA
                });
            }
        }
  
        if (e.keyCode === 38) {
            if( offsetTop + DELTA < topBoundry ) {
                $(id).css({
                    top: $(id).offset().top + DELTA
                });
            }
        }
  
        if (e.keyCode === 40) {
            if( offsetTop + DELTA > -dragViewportHeightDiff + topBoundry ) {
                $(id).css({
                    top: $(id).offset().top - DELTA
                });
            }
        }
    });
}

(function($){
    initializeMap("lottie");
})(jQuery);

var views = {
    '2_city': {
        "id" : "2_city",
        "name" : "Miasto",
        "icon" : "city.svg",
        "background" : "",
        "categories" : {
            "1": {
                'name': 'Centrum monitoringu',
                'points': {
                    "1" : {
                        "posX": 1600, 
                        "posY": 2300,
                        "size" : 3,
                        "type": 'camera',
                        "icon": 'camera.svg',
                        "name" : "Straż Miejska i Policja",
                        "movies" : {
                            "1" : {
                                "name" : "Straż Miejska i Policja",
                                "movie_url" : "city/centrum_monitoringu.mp4",
                                "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Ochrona obiektów komunalnych i urządzeń użyteczności publicznej z jednego miejsca</li><li>Monitorowanie porządku w miejscach publicznych</li><li>Możliwość przeglądania zdarzeń historycznych w systemie CCTV</li><li>Analiza post factum – ekspresowe wyszukanie zdarzeń przez zastosowanie nowych reguł analiz obrazu na nagranym już materiale</li><li>Weryfikacja parkowania w niedozwolonych miejscach, poprawnego przechodzenia przez ulice, kontrola ruchu</li><li>Szybkie wykrywanie przestępstw i ustalanie świadków zdarzenia</li><li>Współdziałanie z właściwymi podmiotami w zakresie ratowania życia i zdrowia obywateli</li><li>Monitorowanie porządku podczas zgromadzeń i imprez publicznych</li><li>Pomoc w usuwaniu awarii technicznych i skutków klęsk żywiołowych</li><li>Zdalna pomoc w trudnych sytuacjach i zagrożeniach</li></ul><h3>Policja</h3><ul><li>Zarządzanie z jednego miejsca ochroną bezpieczeństwa i porządku publicznego</li><li>Monitorowanie i analizowanie stanu bezpieczeństwa, porządku i płynności ruchu na drogach</li><li>Automatyczna weryfikacja samochodów niestosujących się do sygnalizacji świetlnej</li><li>Szybkie ustalenie okoliczności zaistnienia zdarzeń drogowych</li><li>Skuteczne wykrywanie i ściganie sprawców przestępstw i wykroczeń</li><li>Wyszukiwanie skradzionych samochodów online oraz post factum dzięki automatycznemu rozpoznaniu nr rej. samochodu (koloru, modelu, marki, kraju) </li><li>Przekazywanie treści między operatorami, wspólna koordynacja działań</li><li>Alarmowanie o przekroczonej prędkości w punktach oraz średni czas przejazdu między dwoma wskazanymi punktami</li></ul><h3></h3>",
                                "show_gallery" : false,
                                "gallery" : {}
                            }
                        }
                    }
                }
            },
            '2': {
                'name': 'Kamery',
                'points': {
                    "1" : {
                        "posX": 4000, 
                        "posY": 2400,
                        "size" : 3,
                        "type": 'camera',
                        "icon": 'camera.svg',
                        "name" : "Niedozwolone miejsce parkowania",
                        "movies" : {
                            "1" : {
                                "name" : "Niedozwolone miejsce parkowania",
                                "movie_url" : "city/niedozwolone_miejsce_parkowania.mp4",
                                "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Wzrost bezpieczeństwa dzięki automatycznym alarmom wykrywającym zaparkowane samochody w miejscach niedozwolonych (np. przy przejściach dla pieszych - utrata widoczności, bankomaty)</li><li>Szybka reakcja dzięki bezpośredniej współpracy patrolu z operatorem systemu monitoringu wspieranego zaawansowaną analityką obrazu</li><li>Automatyczna weryfikacja zdarzeń poprzez inteligentną analizę obrazu</li></ul>",
                                "show_gallery" : false,
                                "gallery" : {}
                            }
                        }
                    },
                    "2" : {
                        "posX": 2350, 
                        "posY": 3470, 
                        "size" : 3,
                        "type": 'camera',
                        "icon": 'camera.svg',
                        "name" : "Szkoła - niepowołana osoba",
                        "movies" : {
                            "1" : {
                                "name" : "Szkoła - niepowołana osoba",
                                "movie_url" : "city/szkola_dealer.mp4",
                                "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Wykorzystanie analityki wizyjnej - detekcja ruchu w celu automatycznego wysłania alarmów do operatora systemu weryfikującego osoby „szwendające się’’ np. pod szkołą</li><li>Ograniczenie przestępczości w miejscach z zaawansowaną analityką wizyjną (bójki, dealerzy narkotyków itp.)</li><li>Automatyczne generowane ostrzeżeń dźwiękowych (np. „Proszę opuścić strefę”) poprzez zintegrowane systemy tub interkomowych</li></ul>",
                                "show_gallery" : false,
                                "gallery" : {}
                            }
                        }
                    },
                    "3" : {
                        "posX": 2047, 
                        "posY": 2860,
                        "size" : 3,
                        "type": 'camera',
                        "icon": 'camera.svg',
                        "name" : "Ograniczony postój",
                        "movies" : {
                            "1" : {
                                "name" : "Ograniczony postój",
                                "movie_url" : "city/ograniczony_postoj.mp4",
                                "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Weryfikacja naliczania opłat za postój samochodów w miejscach z ograniczonym czasem postoju</li><li>Upłynnienie ruchu dzięki monitoringowi czasu postoju w miejscach o dużym nasileniu ruchu (np. Urzędy)</li><li>Automatyczna weryfikacja zdarzeń poprzez inteligentną analizę obrazu</li></ul>",
                                "show_gallery" : false, 
                                "gallery" : {}
                            }
                        }
                    },
                    "4" : {
                        "posX": 3047, 
                        "posY": 2860,
                        "size" : 3,
                        "type": 'camera',
                        "icon": 'camera.svg',
                        "name" : "Stłuczka na parkingu",
                        "movies" : {
                            "1" : {
                                "name" : "Stłuczka na parkingu",
                                "movie_url" : "city/stluczka_na_parkingu.mp4",
                                "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Weryfikacja zdarzenia (np. stłuczka na parkingu, wymuszenie pierwszeństwa) z wykorzystaniem analizy obiektowej „post factum”</li><li>Bardzo szybkie wykrycie i ustalenie sprawcy dzięki analityce rozpoznawania tablic rejestracyjnych i porównania z bazą CEPiK </li></ul>",
                                "show_gallery" : false,
                                "gallery" : {}
                            }
                        }
                    },
                    "5" : {
                        "posX": 3700, 
                        "posY": 2100,
                        "size" : 3,
                        "type": 'camera',
                        "icon": 'camera.svg',
                        "name" : "Zdewastowany kosz", 
                        "movies" : {
                            "1" : {
                                "name" : "Zdewastowany kosz",
                                "movie_url" : "city/zdewastowany_kosz.mp4",
                                "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Automatyczne alarmowanie operatora w momencie dewastacji obiektów użyteczności publicznej z wykorzystaniem analityki obiektowej (przemieszczenie ławki, przewrócone kosze)</li><li>Przeciwdziałanie dalszej dewastacji dzięki monitoringowi online</li><li>Szybka weryfikacja sprawców wykroczenia dzięki analityce „post factum”</li></ul>",
                                "show_gallery" : false,
                                "gallery" : {}
                            }
                        }
                    },
                }
            },
            '3': {
                'name': 'Komunikacja i pierwsza pomoc',
                'points': {
                    "1" : {
                        "posX": 3400, 
                        "posY": 2000,
                        "size" : 3,
                        "type": 'intercom',
                        "icon": 'intercom.svg',
                        "name" : "Przystanek - pierwsza pomoc", 
                        "movies" : { 
                            "1" : {
                                "name" : "Przystanek - pierwsza pomoc",
                                "movie_url" : "city/starsza_osoba_przystanek.mp4",
                                "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Podniesienie szans na uratowanie życia poprzez natychmiastową reakcję</li><li>Uniknięcie paniki w momencie zagrożenia – przekazywanie zdalnych instrukcji niosących pomoc</li><li>Szybkie ocenienie sytuacji przez operatora systemu dzięki automatycznemu połączeniu dźwięku z obrazem z miejsca zdarzenia</li></ul>",
                                "show_gallery" : false,
                                "gallery" : {}
                            }
                        }
                    },
                    "2" : {
                        "posX": 2320, 
                        "posY": 3370, 
                        "size" : 3,
                        "type": 'intercom',
                        "icon": 'intercom.svg',
                        "name" : "Szkoła - pierwsza pomoc", 
                        "movies" : {
                            "1" : {
                                "name" : "Szkoła - pierwsza pomoc",
                                "movie_url" : "city/klasa_pomoc.mp4",
                                "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Wzrost bezpieczeństwa - bezpośrednie wezwanie pomocy przez interkom umieszczony w klasie</li><li>Skuteczność szybkiej reakcji – bezpośredni kontakt z sekretariatem szkoły</li><li>Ograniczenie niebezpieczeństwa / przemocy w szkole - funkcja audio-monitoringu automatyczne inicjuje połączenie głosowe z sekretariatem w momencie przekroczenia określonego poziomu hałasu</li><li>Jedyne na rynku rozwiązanie z normą PN-EN IEC 62820-3-2:2018-07 / ASBIS (EU) - w przypadku zagrożenia następuje aktywacja trybu ewakuacji – separacja uczniów od napastnika</li></ul>",
                                "show_gallery" : false,
                                "gallery" : {}
                            }
                        }
                    },
                    "3" : {
                        "posX": 3560, 
                        "posY": 2130,
                        "size" : 3,
                        "type": 'intercom',
                        "icon": 'intercom.svg',
                        "name" : "Bójka w parku", 
                        "movies" : {
                            "1" : {
                                "name" : "Bójka w parku",
                                "movie_url" : "city/bojka_w_parku.mp4",
                                "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Wzrost bezpieczeństwa - automatyczne wygenerowanie obrazu na stacji operatorskiej dzięki zastosowanym funkcjom analityki obrazu (detekcja osób, analityka tzw. szwendanie się)</li><li>Zmniejszenie ilości wykroczeń / przejawów przemocy - bezpośrednie nadanie komunikatów z interkomu przez operatora systemu monitoringu</li><li>Automatyczne archiwizowanie materiałów dowodowych</li><li>Prewencyjne podnoszenie bezpieczeństwa osób i mienia - automatyczne generowanie komunikatów np. w porze wieczornej „Jesteś monitorowany czuj się bezpiecznie”</li></ul>",
                                "show_gallery" : false,
                                "gallery" : {}
                            }
                        }
                    }
                }
            },
            '4': {
                'name': 'Bezpieczne drogi',
                'points': {
                    "1" : {
                        "posX": 1400, 
                        "posY": 3400,
                        "size" : 3,
                        "type": 'camera',
                        "icon": 'camera.svg',
                        "name" : "Skradzione auto", 
                        "movies" : {
                            "1" : {
                                "name" : "Skradzione auto",
                                "movie_url" : "city/skradzione_auto.mp4",
                                "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Wzrost szybkości wykrywania złodziei samochodów - automatyczne wyszukiwanie skradzionych samochodów wg min. jednego z parametrów: nr rejestracyjnego samochodu, koloru, marki, modelu, typu samochodu (ciężarowy), kraju rejestracji, itp.</li><li>Reakcja na zdarzenie w trybie na żywo – natychmiastowe alarmowanie odpowiednich służb oraz odtworzenie ścieżek poruszania się podejrzanych</li><li>Moduły raportowo - statystyczne dla służb drogowych - wykorzystanie do optymalizacji przejazdów - wygenerowanie zielonej fali, rozładowanie korków, możliwość integracji automatycznej z systemami sterowania ruchem czy ITS </li><li>Możliwość pomiaru średniego czasu przejazdu</li></ul>",
                                "show_gallery" : false,
                                "gallery" : {}
                            }
                        }
                    },
                    "2" : {
                        "posX": 3400, 
                        "posY": 2050,
                        "size" : 3,
                        "type": 'camera',
                        "icon": 'camera.svg',
                        "name" : "Czerwone światło", 
                        "movies" : {
                            "1" : {
                                "name" : "Czerwone światło",
                                "movie_url" : "city/czerwone_swiatlo.mp4",
                                "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Automatyczne wykrycie aut przejeżdżających na czerwonym świetle - integracja z zaawansowanymi funkcjami analityki obrazu rozpoznającymi numery rejestracyjne pojazdów</li><li>Reakcja na zdarzenie w trybie na żywo – natychmiastowe alarmowanie odpowiednich służb oraz odtworzenie ścieżek poruszania się pojazdu</li></ul>",
                                "show_gallery" : false,
                                "gallery" : {}
                            }
                        }
                    }
                }
            },
            '5': {
                'name': 'Oświetlenie',
                'points': {
                    "1" : {
                        "posX": 4450, 
                        "posY": 1400,
                        "size" : 3,
                        "type": 'camera',
                        "icon": 'camera.svg',
                        "name" : "Przejście podziemne", 
                        "movies" : {
                            "1" : {
                                "name" : "Przejście podziemne",
                                "movie_url" : "city/oswietlenie_przejscie_podziemne.mp4",
                                "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Oszczędność energii elektrycznej - obniżanie poziomu natężenia światła po upływie zdefiniowanego czasu bezczynności w każdej z wyznaczonych stref</li><li>Dbanie o środowisko <i>cradle to cradle</i> - \"gospodarka o obiegu zamkniętym\", gdzie cykl użytkowania produktów polega na wykorzystaniu odpadów w kolejnym procesie wytwarzania</li></ul>",
                                "show_gallery" : false,
                                "gallery" : {}
                            }
                        }
                    },
                }
            },
            '6': {
                'name': 'Parkingi',
                'points': {
                    "1" : {
                        "posX": 1700,  
                        "posY": 2970,
                        "size" : 3,
                        "type": 'camera',
                        "icon": 'camera.svg',
                        "name" : "Aplikacja parkingowa", 
                        "movies" : {
                            "1" : {
                                "name" : "Aplikacja parkingowa",
                                "movie_url" : "city/aplikacja_parkingowa.mp4",
                                "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Oszczędność czasu i wygoda dla mieszkańców – szybka możliwość znalezienia wolnego miejsca parkingowego</li><li>Rozładowanie natężenia ruchu – przekierowanie do wolnych stref parkingowych</li></ul>",
                                "show_gallery" : false,
                                "gallery" : {}
                            }
                        }
                    },
                    "2" : {
                        "posX": 2900, 
                        "posY": 2670,
                        "size" : 3,
                        "type": 'camera',
                        "icon": 'camera.svg',
                        "name" : "Wjazd i opłata za parking", 
                        "movies" : {
                            "1" : {
                                "name" : "Wjazd i opłata za parking",
                                "movie_url" : "city/wjazd_na_parking.mp4",
                                "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Bezbiletowa, szybka obsługa klientów parkingów - automatyczny odczyt nr rejestracyjnych na wjeździe i wyjeździe z parkingu. Przed wyjazdem klient wpisuje nr rej pojazdu w systemie opłat i system automatycznie umożliwia wyjazd z parkingu.</li><li>Integracja systemu wizyjnego z systemem opłat za parking</li><li>Integracja z systemami karty miejskiej - bezgotówkowe transakcje na bazie analiz tablic</li><li>Tworzenie zcentralizowanych zawansowanych systemów parkingów miejskich P&R</li></ul>",
                                "show_gallery" : false,
                                "gallery" : {}
                            }
                        }
                    }
                }
            }
        },
        "points" : {

        }
    },
    '1_office': {
        "id" : "1_office",
        "name" : "Biurowiec",
        "icon" : "office.svg",
        "background" : "office_background.png",
        "points" : {
            "1" : {
                "position_top" : 68,
                "position_left" : 16,
                "size" : 3,
                "movies" : {
                    "1" : {
                        "name" : "Wjazd na parking – autoryzacja tablicą rejestracyjną",
                        "movie_url" : "office/autoryzacja_tablicy_rejestracyjnej.mp4",
                        "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Zarządzenie dostępem pracowników i gości do parkingu</li><li>Sprawdzanie zajętości parkingu</li><li>Otwieranie bramek parkingowych na podstawie analizy tablic rejestracyjnych</li><li>Rezerwacja miejsc parkingowych przez internet</li></ul>",
                        "show_gallery" : true,
                        "gallery" : {
                            "1" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/parking_autoryzacja_tablicy/01_autoryzacja_tablicy.jpg"
                            },
                            "2" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/parking_autoryzacja_tablicy/02_autoryzacja_tablicy.jpg"
                            },
                            "3" : {
                                "type" : "video",
                                "url" : "dist/img/presentation/parking_autoryzacja_tablicy/03_autoryzacja_tablicy.mp4"
                            }
                        }
                    },
                    "2" : {
                        "name" : "Wjazd na parking – autoryzacja kartą KD",
                        "movie_url" : "office/autoryzacja_karty_kd.mp4",
                        "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Zarządzenie dostępem pracowników i gości do parkingu</li><li>Sprawdzanie zajętości parkingu</li><li>Otwieranie bramek parkingowych na podstawie analizy tablic rejestracyjnych</li></ul>",
                        "show_gallery" : true,
                        "gallery" : {
                            "1" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/parking_autoryzacja_kd/01_parking_autoryzacja_kd.jpg"
                            },
                            "2" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/parking_autoryzacja_kd/02_parking_autoryzacja_kd.jpg"
                            },
                            "3" : {
                                "type" : "video",
                                "url" : "dist/img/presentation/parking_autoryzacja_kd/03_parking_autoryzacja_kd.mp4"
                            }
                        }
                    },
                    "3" : {
                        "name" : "Wjazd na parking – użycie interkomu",
                        "movie_url" : "office/wjzad_kuriera.mp4",
                        "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Komunikacja ze stanowiskiem ochrony</li><li>Pełna kontrola pojazdów wjeżdżających na teren obiektu</li><li>Sprawdzanie zajętości parkingu</li></ul>",
                        "show_gallery" : true,
                        "gallery" : {
                            "1" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/kurier/01_kurier.jpg"
                            },
                            "2" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/kurier/02_kurier.jpg"
                            },
                            "3" : {
                                "type" : "video",
                                "url" : "dist/img/presentation/kurier/03_kurier.mp4"
                            }
                        }
                    }
                }
            },
            "2" : {
                "position_top" : 79,
                "position_left" : 50,
                "size" : 1,
                "movies" : {
                    "1" : {
                        "name" : "Detekcja intruza na parkingu",
                        "movie_url" : "office/detekcja_intruza.mp4",
                        "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Bezpieczeństwo mienia</li><li>Możliwość zastosowania dowolnej kamery - w tym istniejących kamer analogowych</li><li>Analiza post-factum 2.0</li><li>Wsparcie dla operatora</li></ul>",
                        "show_gallery" : true,
                        "gallery" : {
                            "1" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/parking_intruz/01_parking_intruz.jpg"
                            },
                            "2" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/parking_intruz/02_parking_intruz.gif"
                            },
                            "3" : {
                                "type" : "video",
                                "url" : "dist/img/presentation/parking_intruz/03_parking_intruz.mp4"
                            }
                        }
                    }
                }
            },
            "3" : {
                "position_top" : 89,
                "position_left" : 62,
                "size" : 1,
                "movies" : {
                    "1" : {
                        "name" : "Detekcja intruza przy płocie",
                        "movie_url" : "office/intruz_plot.mp4",
                        "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Sygnalizacja prób wejścia osób niepowołanych na teren biurowca</li><li> Przesyłanie sygnału alarmu do centrum powiadamiania</li><li>Pełna wizualizacja statusu detektorów i stref na stanowisku ochrony</li><li>Automatyczne zazbrajanie i rozbrajanie stref bezpieczeństwa w oparciu o zaplanowany harmonogram</li><li>Ochrona aut na parkingu</li></ul>",
                        "show_gallery" : true,
                        "gallery" : {
                            "1" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/plot_intruz/01_plot_intruz.jpg"
                            },
                            "2" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/plot_intruz/02_plot_intruz.gif"
                            },
                            "3" : {
                                "type" : "video",
                                "url" : "dist/img/presentation/plot_intruz/03_plot_intruz.mp4"
                            }
                        }
                    }
                }
            },
            "4" : {
                "position_top" : 20,
                "position_left" : 62,
                "size" : 3,
                "movies" : {
                    "1" : {
                        "name" : "Kontrola dostępu z podwójną weryfikacją",
                        "movie_url" : "office/odbicie_karty_powiazanej_z_cctv.mp4",
                        "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Weryfikacja osób posługujących się kartami kontroli dostępu</li><li>Możliwość przeglądania zdarzeń historycznych w systemie CCTV 'jednym kliknięciem'</li></ul>",
                        "show_gallery" : true,
                        "gallery" : {
                            "1" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/karta_cctv/01_karta_cctv.jpg"
                            },
                            "2" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/karta_cctv/02_karta_cctv.jpg"
                            },
                            "3" : {
                                "type" : "video",
                                "url" : "dist/img/presentation/karta_cctv/03_karta_cctv.mp4"
                            }
                        }
                    },
                    "2" : {
                        "name" : "Identyfikacja osób – rozpoznanie twarzy",
                        "movie_url" : "office/rozpoznanie_niepozadanej_osoby.mp4",
                        "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Weryfikacja poruszających się osób po obiekcie</li><li>Automatyczne alarmowanie o obecności nieporządanej osoby w budynku</li></ul>",
                        "show_gallery" : true,
                        "gallery" : {
                            "1" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/niepozadana_osoba/01_niepozadana_osoba.jpg"
                            },
                            "2" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/niepozadana_osoba/02_niepozadana_osoba.gif"
                            },
                            "3" : {
                                "type" : "video",
                                "url" : "dist/img/presentation/niepozadana_osoba/03_niepozadana_osoba.mp4"
                            }
                        }
                    },
                    "3" : {
                        "name" : "Użycie skradzionej karty",
                        "movie_url" : "office/uzycie_skradzionej_karty.mp4",
                        "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Powiadomienia dla operatora po użyciu karty skradzionej</li><li>Zapobieganie wstępu osobom nieupoważnionym</li><li>możliwość automatycznego zablokowania wyjścia w celu ujęcia sprawcy</li></ul>",
                        "show_gallery" : true,
                        "gallery" : {
                            "1" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/skradziona_karta/01_skradziona_karta.jpg"
                            },
                            "2" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/skradziona_karta/02_skradziona_karta.jpg"
                            },
                            "3" : {
                                "type" : "video",
                                "url" : "dist/img/presentation/skradziona_karta/03_skradziona_karta.mp4"
                            }
                        }
                    }
                }
            },
            "5" : {
                "position_top" : 33,
                "position_left" : 35,
                "size" : 1,
                "movies" : {
                    "1" : {
                        "name" : "Zdalnie zazbrajanie systemu sygnalizacji włamania i napadu",
                        "movie_url" : "office/przelaczenie_obiektoow_zdalnych_w_tryb_zazbrojenia.mp4",
                        "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Zintegrowany system zabezpieczeń oddziałów firmy w różnych miastach </li><li>Zarządzanie bezpieczeństwem z jednego miejsca </li><li>Jeden portier otrzymuje sygnały alarmowe z różnych lokalizacji</li><li>Mniejsze nakłady na sprzęt zarządzający i obsługę</li></ul>",
                        "show_gallery" : true,
                        "gallery" : {
                            "1" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/przebrajanie/01_przebrajanie.jpg"
                            },
                            "2" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/przebrajanie/02_przebrajanie.jpg"
                            },
                            "3" : {
                                "type" : "video",
                                "url" : "dist/img/presentation/przebrajanie/03_przebrajanie.mp4"
                            }
                        }
                    }
                }
            },
            "6" : {
                "position_top" : 79,
                "position_left" : 23,
                "size" : 3,
                "movies" : {
                    "1" : {
                        "name" : "Wejście pod przymusem",
                        "movie_url" : "office/wejscie_pod_przymusem.mp4",
                        "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Cichy alarm w trosce o obezwładnioną osobę</li><li>Powiadomienia dla operatora i służb o zdarzeniu</li></ul>",
                        "show_gallery" : true,
                        "gallery" : {
                            "1" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/wejscie_pod_przymusem/01_wejscie_pod_przymusem.jpg"
                            },
                            "2" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/wejscie_pod_przymusem/02_wejscie_pod_przymusem.gif"
                            },
                            "3" : {
                                "type" : "video",
                                "url" : "dist/img/presentation/wejscie_pod_przymusem/03_wejscie_pod_przymusem.mp4"
                            }
                        }
                    },
                    "2" : {
                        "name" : "Włamanie",
                        "movie_url" : "office/wlamanie.mp4",
                        "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Natychmiastowe powiadomienie operatora z wyświetleniem obrazu</li><li>Ochrona mienia</li><li>Wsparcie dla grupy interwencyjnej</li></ul>",
                        "show_gallery" : true,
                        "gallery" : {
                            "1" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/wlamanie/01_wlamanie.jpg"
                            },
                            "2" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/wlamanie/02_wlamanie.jpg"
                            },
                            "3" : {
                                "type" : "video",
                                "url" : "dist/img/presentation/wlamanie/03_wlamanie.mp4"
                            }
                        }
                    },
                    "3" : {
                        "name" : "Wejście do budynku z użyciem interkomu",
                        "movie_url" : "office/zdalne_otwarcie_bramy_w_jednostce_podleglej.mp4",
                        "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Zarządzenie dostępem pracowników i gości do budynków</li><li>Pełna kontrola osób chcących dostać się na teren obiektu</li><li>Zarządzanie bezpieczeństwem z jednego miejsca</li></ul>",
                        "show_gallery" : false
                    }
                }
            },
            "7" : {
                "position_top" : 78,
                "position_left" : 33,
                "size" : 2,
                "movies" : {
                    "1" : {
                        "name" : "Wzbudzenie alarmu PPOŻ",
                        "movie_url" : "office/wzbudzenie_alarmu_ppoz.mp4",
                        "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>detekcja pożaru</li><li>powiadamianie o zagrożeniu pożarowym za pomocą sygnalizatorów pętlowych oraz konwencjonalnych</li><li>wykonywanie funkcji sterujących za pomocą modułów wejścia/wyjścia z wbudowanym izolatorem</li><li>możliwość zastosowania bezprzewodowych elementów pętlowych</li><li>w pełni cyfrowe i adresowalne elementy pętlowe</li><li>wizualizacja występujących zdarzeń w czasie rzeczywistym</li></ul>",
                        "show_gallery" : true,
                        "gallery" : {
                            "1" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/alarm_ppoz/01_alarm_ppoz.gif"
                            },
                            "2" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/alarm_ppoz/02_alarm_ppoz.jpg"
                            },
                            "3" : {
                                "type" : "video",
                                "url" : "dist/img/presentation/alarm_ppoz/03_alarm_ppoz.mp4"
                            }
                        }
                    },
                    "2" : {
                        "name" : "Zbyt wysoka temperatura w monitorowanym pomieszczeniu",
                        "movie_url" : "office/zbyt_wysoka_temperatura_w_budynku_zdalnym_poprawki.mp4",
                        "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Przeciwdziałanie uszkodzeniu sprzętu</li><li>Zapobieganie utraty danych</li><li>Ułatwienie zdalnego zarządzania dla służb utrzymaniowych</li></ul>",
                        "show_gallery" : true,
                        "gallery" : {
                            "1" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/zbyt_wysoka_temp/01_zbyt_wysoka_temp.gif"
                            },
                            "2" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/zbyt_wysoka_temp/02_zbyt_wysoka_temp.jpg"
                            },
                            "3" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/zbyt_wysoka_temp/03_zbyt_wysoka_temp.jpg"
                            }
                        }
                    }
                }
            },
            "8" : {
                "position_top" : 54,
                "position_left" : 57,
                "size" : 1,
                "movies" : {
                    "1" : {
                        "name" : "Wzbudzenie fałszywego alarmu PPOŻ",
                        "movie_url" : "office/wzbudzenie_falszywego_alarmu_ppoz.mp4",
                        "description" : "<h3>Możliwości i zastosowanie</h3><ul><li>Eliminacja fałszywych alarmów dzięki integracji z CCTV</li><li>Minimalizacja kosztów obsługi PPOŻ przez służby straży pożarnej</li><li>Zapobieganie nieuzasadnionym ewakuacjom budynku</li></ul>",
                        "show_gallery" : true,
                        "gallery" : {
                            "1" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/falszywy_pozar/01_falszywy_pozar.gif"
                            },
                            "2" : {
                                "type" : "image",
                                "url" : "dist/img/presentation/falszywy_pozar/02_falszywy_pozar.jpg"
                            },
                            "3" : {
                                "type" : "video",
                                "url" : "dist/img/presentation/falszywy_pozar/03_falszywy_pozar.mp4"
                            }
                        }
                    }
                }
            }
        }
    }
};