(function($){
    /**
     * ładowanie obrazów w tle
     */
    function preload() {
        var images = [];
        for (var i = 0; i < arguments.length; i++) {
            images[i] = new Image();
            images[i].src = preload.arguments[i];
        }
    }

    preload(
        'dist/img/movies/office_background.png',
        'dist/img/movies/park_background.png'
    )
    
})(jQuery);