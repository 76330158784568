(function($){
    /**
     * rozwijanie menu w widoku obiektu
     */
    $(document).on('mouseenter', '.js-map-pin', function() {
        var element = $(this).find('.js-animation-list')
        var container = $(this).find('.js-animation-list-container')
        var height = $(this).find('.js-animation-list').outerHeight()
        container.css('height', height)
    })

    $(document).on('mouseleave', '.js-map-pin', function() {
        var container = $(this).find('.js-animation-list-container')
        container.css('height', 0)
    })
})(jQuery);